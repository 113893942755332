import { useEffect, useState, useRef, useMemo } from 'react';
import { useWindowScroll } from 'react-use';

const useInView = (keepToggled = false) => {
  const [isCenter, setIsCenter] = useState(false);
  const ref = useRef(null);
  const [inView, setInView] = useState(false);
  const { y } = useWindowScroll();

  const callback = entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        return setInView(true);
      }
      return setInView(false);
    });
  };

  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  };

  const observer = useMemo(() => {
    if (typeof window === 'undefined') return;
    if (window.IntersectionObserver)
      return new IntersectionObserver(callback, options);
    return null;
  }, []);

  // Check if ref element is centered to viewport
  useEffect(() => {
    if (ref.current && observer) {
      observer.observe(ref.current);
    }

    if ((keepToggled && inView) || (keepToggled && isCenter)) return;

    if (ref?.current?.offsetTop <= window.scrollY + window.innerHeight / 2) {
      return setIsCenter(true);
    }

    if (ref.current && observer) {
      observer.unobserve(ref.current);
    }

    setInView(false);
    setIsCenter(false);
  }, [y, ref]);

  if (!ref) {
    return;
  }

  return { ref, isCenter, inView };
};

export default useInView;
